import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { AccountClient, UserClient } from 'app/apis/api.js';
import type { Action } from 'redux-actions';
import * as UserManagementActions from 'app/actions/userManagement/userManagementActions.js';
import type UserManaged from '../../../models/userManagement/userManaged.js';
import type { EditUserCommand, UserDto } from '@/generated-api/index.js';
import { UserManagementSelectors } from '../../../selectors/index.js';
import type { Unwrap } from 'app/types/UtilityType.js';
import logger from 'app/utils/logger.js';

function* getUsers() {
  yield put(UserManagementActions.setUsersLoaded(false));
  try {
    const { data }: Unwrap<typeof UserClient['userGetUsers']> = yield UserClient.userGetUsers();

    yield put(UserManagementActions.setUsers((data as unknown) as UserManaged[]));
  } catch (error) {
    logger.log(error);
  } finally {
    yield put(UserManagementActions.setUsersLoaded(true));
  }
}

function* editUser(action: Action<{ user: UserManaged; onSuccess?: () => void }>) {
  const { user: userData, onSuccess } = action.payload;

  yield put(UserManagementActions.setUsersLoaded(false));

  try {
    const targetUser: UserManaged = yield select(UserManagementSelectors.userById(userData.id));

    const payload: EditUserCommand = {
      id: userData.id,
      role: (userData.role as number) ?? (targetUser?.role as number),
      phone: userData.phone ?? targetUser?.phone,
      departmentId: userData.departmentId || null,
      name: userData.name,
      hideScenarioContent: userData.hideScenarioContent,
      showCadForm: userData.showCadForm
    };

    const response = yield call([AccountClient, AccountClient.accountEditUser], payload);

    yield put(UserManagementActions.userUpdated(response.data as UserDto));

    onSuccess?.();
  } catch (error) {
    logger.log(error);
  } finally {
    yield put(UserManagementActions.setUsersLoaded(true));
  }
}

function* deleteUser(action: Action<{ id: number; onSuccess?: () => void }>) {
  const { id, onSuccess } = action.payload;

  yield put(UserManagementActions.setUsersLoaded(false));

  try {
    yield call([AccountClient, UserClient.userDeleteUser], id);

    yield put(UserManagementActions.userDeleted(id));

    onSuccess?.();
  } catch (error) {
    logger.log(error);
  } finally {
    yield put(UserManagementActions.setUsersLoaded(true));
  }
}

function* watchGetUsers() {
  yield takeLatest(UserManagementActions.Type.GET_USERS, getUsers);
}

function* watchEditUser() {
  yield takeLatest(UserManagementActions.Type.EDIT_USER, editUser);
}

function* watchDeleteUser() {
  yield takeLatest(UserManagementActions.Type.DELETE_USER, deleteUser);
}

export function* watchUserManagement() {
  yield fork(watchGetUsers);
  yield fork(watchEditUser);
  yield fork(watchDeleteUser);
}
